import { startTransition } from "react";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "src/pages/routePaths";
import { OverviewNotification } from "../api/interfaces";

interface UseNotificationNavigationProps {
  notification: OverviewNotification;
}

const useNotificationNavigation = ({
  notification,
}: UseNotificationNavigationProps) => {
  const navigate = useNavigate();

  const handleNavigateNotification = () => {
    let navigationPath = "";

    switch (notification.type) {
      case "NEW_ORDER":
        navigationPath = `${RoutePaths.OrderManagementCustomerTransactionList}/${notification.transaction.id}/detail`;
        break;
      case "WAITING_CONFIRMATION":
        navigationPath = `${RoutePaths.OrderManagementCustomerTransactionList}/${notification.transaction.id}/detail`;
        break;
      case "RETURN_ITEM":
        navigationPath = `${RoutePaths.OrderManagementReturnItemList}/${notification.transaction.id}`;
        break;
      case "ON_FULFILLED":
        navigationPath = `${RoutePaths.OrderManagementOrderList}/${notification.transaction.id}`;
        break;
      case "LOW_STOCK":
        navigationPath = `${RoutePaths.ProductManagementProductList}/${notification.product_variant.id}`; // TODO: to ask BE to return product id
        break;
      case "ADJUSTED_STOCK":
        navigationPath = `${RoutePaths.ProductManagementProductList}/${notification.product_variant.id}`; // TODO: to ask BE to return product id
        break;
      default:
        break;
    }

    return startTransition(() => navigate(navigationPath));
  };

  return {
    handleNavigateNotification,
  };
};

export default useNotificationNavigation;
