import { SidebarLeft, SidebarRight } from "iconsax-react";
import React, { ReactNode, startTransition, useEffect } from "react";
import SideBar from "src/components/SideBar";
import AdminDropdownMenu from "./AdminDropdownMenu";
import useLayoutSidebar from "./hooks/useLayoutSidebar";
import ChangePasswordModal from "src/components/ChangePasswordModal";
import { useGetPagePermission } from "src/hooks/useGetPagePermission";
import { AccessResource, ActionType } from "src/types/accessControl";
import NotificationDropdownMenu from "./NotificationDropdownMenu";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "src/pages/routePaths";

interface LayoutWithSideBarProps {
  children: React.ReactNode;
  title: string;
  resource: AccessResource | null;
  action: ActionType;
  header?: ReactNode;
  headerAction?: ReactNode;
  children2?: React.ReactNode;
  children2Left?: React.ReactNode;
  noCanvas?: boolean;
  isColumn?: boolean;
}

const LayoutWithSideBar: React.FC<LayoutWithSideBarProps> = ({
  children,
  title,
  resource,
  action,
  header,
  headerAction,
  children2,
  children2Left,
  noCanvas,
  isColumn,
}) => {
  useGetPagePermission({
    resource,
    action,
  });

  const navigate = useNavigate();

  // TODO: to remove the isProduction
  const isProduction = process.env.REACT_APP_NODE_ENV === "production";

  useEffect(() => {
    if (isProduction && resource) {
      if (
        [
          AccessResource["Report | Item Sales Report"],
          AccessResource["Report | Cost Report"],
          AccessResource["Report | PnL Report"],
        ].includes(resource)
      ) {
        startTransition(() => {
          navigate(RoutePaths.Welcome, { replace: true });
        });
      }
    }
  }, [isProduction, navigate, resource]);

  const {
    adminId,
    isSidebarOpen,
    isOpenNotification,
    toggleSidebar,
    toggleNotification,
    pathname,
    isShowChangeMyPasswordModal,
    handleChangeMyPassword,
    handleCancelChangeMyPassword,
  } = useLayoutSidebar();

  return (
    <div
      className={`grid min-h-screen w-full transition-all duration-300 ease-in-out ${
        isSidebarOpen
          ? "md:grid-cols-[220px_1fr] lg:grid-cols-[280px_1fr]"
          : "grid-cols-[0_1fr]"
      }`}
    >
      <SideBar isOpen={isSidebarOpen} />

      <div className="flex flex-col bg-background pb-24">
        <header className="flex sticky top-0 bg-white z-50 h-14 items-center gap-4 lg:h-[71px] shadow-md">
          <div className="w-full flex-1">
            <div
              className={`lg:px-6 flex items-center gap-2 transition-all duration-300 ease-in-out opacity-100 text-darkBrown-default`}
            >
              <button onClick={toggleSidebar} className="focus:outline-none">
                {isSidebarOpen ? (
                  <SidebarLeft className="w-8 h-8" />
                ) : (
                  <SidebarRight className="w-8 h-8" />
                )}
              </button>

              <span className="text-xl 2xl:text-2xl font-bold capitalize text-nowrap">
                {pathname}
              </span>
            </div>
          </div>

          {header}

          <NotificationDropdownMenu
            isOpen={isOpenNotification}
            handleToggle={toggleNotification}
          />
          <AdminDropdownMenu handleChangeMyPassword={handleChangeMyPassword} />
        </header>

        <div className={`flex ${isColumn ? "flex-col" : "flex-row"}`}>
          {children2Left}

          <main
            className={`flex-1 flex flex-col gap-4 m-4 xl:m-6 ${
              noCanvas
                ? ""
                : "bg-darkBrown-10 p-4 lg:gap-6 lg:p-5 rounded-2xl border"
            }`}
          >
            <div className={`flex items-center justify-between`}>
              {title !== "" && (
                <h1 className="text-lg font-semibold text-darkBrown-default md:text-2xl">
                  {title}
                </h1>
              )}

              {headerAction}
            </div>

            <div x-chunk="dashboard-02-chunk-1" className="grid gap-5">
              {children}
            </div>
          </main>

          {isColumn ? (
            <div className="flex-1 flex flex-col gap-4 m-4 xl:m-6 !mt-0">
              {children2}
            </div>
          ) : (
            <>{children2}</>
          )}
        </div>
      </div>

      <ChangePasswordModal
        isShow={isShowChangeMyPasswordModal}
        onClose={handleCancelChangeMyPassword}
        adminId={adminId}
        type="MINE"
      />
    </div>
  );
};

export default LayoutWithSideBar;
