interface RoutesConfig {
  SignIn: string;

  UnAuthenticated: string;

  Welcome: string;
  Notification: string;
  Overview: string;

  POS: string;
  POSTransactionSummary: string;
  POSPayment: string;

  OrderManagementOrderList: string;
  OrderManagementCustomerTransactionList: string;
  OrderManagementSubOutTransactionList: string;
  OrderManagementReturnItemList: string;

  UserManagementCustomerList: string;
  UserManagementCreateCustomerList: string;

  UserManagementPartnerOrVendorList: string;

  UserManagementSupplierList: string;

  ProductManagementStockPurchase: string;
  ProductManagementCreateStockPurchase: string;

  ProductManagementInventory: string;

  ProductManagementProductList: string;
  ProductManagementCreateProductList: string;

  ProductManagementProductPackage: string;
  ProductManagementCreateProductPackage: string;

  ProductManagementWarehouse: string;
  ReportOverviewReport: string;
  ReportSalesReport: string;
  ReportProductUsageReport: string;
  ReportCostReport: string;
  ReportPnLReport: string;

  PromoCode: string;
  CreatePromoCode: string;

  AdminManagementAdminList: string;
  AdminManagementCreateAdminList: string;

  AdminManagementAdminActionLog: string;

  AdminManagementAdminRole: string;
  AdminManagementCreateAdminRole: string;

  MasterDataVendorOrPartnerList: string;
  MasterDataCreateVendorOrPartnerList: string;

  MasterDataSupplierList: string;
  MasterDataCreateSupplierList: string;

  MasterDataBusinessOperation: string;
  MasterDataCreateBusinessOperation: string;

  MasterDataAdditionalFee: string;

  MasterDataWarehouseData: string;
  MasterDataProductCategory: string;
  MasterDataPackageCategory: string;
  MasterDataCustomerType: string;
  MasterDataItemStockUnit: string;
}

export const RoutePaths: RoutesConfig = {
  SignIn: "/sign-in",

  UnAuthenticated: "/forbidden",

  Welcome: "/",
  Overview: "/overview",
  Notification: "/notification",

  POS: "/pos",
  POSTransactionSummary: "/pos/transaction-summary",
  POSPayment: "/pos/payment",

  OrderManagementOrderList: "/order-management/order-list",
  OrderManagementCustomerTransactionList:
    "/order-management/customer-transaction-list",
  OrderManagementSubOutTransactionList:
    "/order-management/sub-out-transaction-list",
  OrderManagementReturnItemList: "/order-management/return-item-list",

  UserManagementCustomerList: "/user-management/customer",
  UserManagementCreateCustomerList: "/user-management/customer/create",

  UserManagementPartnerOrVendorList: "/user-management/partner-or-vendor-list",

  UserManagementSupplierList: "/user-management/supplier-list",

  ProductManagementStockPurchase: "/product-management/stock-purchase",
  ProductManagementCreateStockPurchase:
    "/product-management/stock-purchase/create",

  ProductManagementInventory: "/product-management/inventory",

  ProductManagementProductList: "/product-management/product-list",
  ProductManagementCreateProductList: "/product-management/product-list/create",

  ProductManagementProductPackage: "/product-management/product-package",
  ProductManagementCreateProductPackage:
    "/product-management/product-package/create",

  ProductManagementWarehouse: "/product-management/warehouse",

  ReportOverviewReport: "/report/overview-report",
  ReportSalesReport: "/report/sales-report",
  ReportProductUsageReport: "/report/product-usage-report",
  ReportCostReport: "/report/cost-report",
  ReportPnLReport: "/report/profit-and-lost-report",

  PromoCode: "/promo-code",
  CreatePromoCode: "/promo-code/create",

  AdminManagementAdminList: "/admin-management/admin",
  AdminManagementCreateAdminList: "/admin-management/admin/create",

  AdminManagementAdminActionLog: "/admin-management/admin-action-log",

  AdminManagementAdminRole: "/admin-management/admin-role",
  AdminManagementCreateAdminRole: "/admin-management/admin-role/create",

  MasterDataVendorOrPartnerList: "/master-data/vendor-or-partner-list",
  MasterDataCreateVendorOrPartnerList:
    "/master-data/vendor-or-partner-list/create",

  MasterDataSupplierList: "/master-data/supplier-list",
  MasterDataCreateSupplierList: "/master-data/supplier-list/create",

  MasterDataBusinessOperation: "/master-data/business-operation",
  MasterDataCreateBusinessOperation: "/master-data/business-operation/create",

  MasterDataAdditionalFee: "/master-data/additional-fee",
  MasterDataWarehouseData: "/master-data/warehouse-data",
  MasterDataProductCategory: "/master-data/product-category",
  MasterDataPackageCategory: "/master-data/package-category",
  MasterDataCustomerType: "/master-data/customer-type",
  MasterDataItemStockUnit: "/master-data/item-stock-unit",
};
